.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Navigation styling */
.app-navigation {
  background-color: #2c3e50;
  padding: 15px 0;
  margin-bottom: 20px;
}

.app-navigation ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
}

.app-navigation li {
  margin: 0 15px;
}

.app-navigation a {
  color: white;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  padding: 8px 15px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.app-navigation a:hover {
  background-color: #34495e;
}

.main-content {
  padding: 0 20px;
}

.filter-controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;
  gap: 15px;
}

.filter-controls label {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.filter-controls input[type="checkbox"] {
  margin-right: 5px;
}

.filter-controls input[type="number"] {
  width: 80px;
  margin-left: 5px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.clear-teams-btn {
  background-color: #007bff; /* Bootstrap primary blue, for example */
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.clear-teams-btn:hover {
  background-color: #0056b3;
}

.scatter-plot-container {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Adjust if you want to align them differently */
  gap: 20px; /* Optional: adds some space between the chart and the list */
}

.scatter-plot, .selected-teams-list {
  flex: 1; /* Adjust these values as needed */
}

/* Make sure the chart container has a defined height */
.scatter-plot {
  height: 900px; /* Adjust based on your needs */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.filters {
  margin: 1rem 0;
  display: flex;
  gap: 1rem;
}

.filters label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.filters input {
  padding: 0.25rem;
  width: 100px;
}

.print-teams-button {
  margin: 1rem;
  padding: 0.5rem 1rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.print-teams-button:hover {
  background-color: #45a049;
}
