.tournament-bracket-container {
  padding: 20px;
  max-width: 100%;
  overflow-x: auto;
}

.tournament-bracket {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  min-width: 1200px;
}

.round {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 10px;
}

.round-title {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
  color: #333;
}

.round-matchups {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

.matchup {
  margin: 10px 0;
  border-radius: 8px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
}

.matchup-teams {
  display: flex;
  flex-direction: column;
}

.matchup-details {
  padding: 5px 10px;
  font-size: 12px;
  color: #666;
  background-color: #eee;
  text-align: center;
}

.bracket-team {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
  transition: background-color 0.2s;
}

.bracket-team:last-child {
  border-bottom: none;
}

.bracket-team.hovered {
  background-color: #e6f7ff;
}

.team-seed-name {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.seed {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background-color: #333;
  color: white;
  border-radius: 50%;
  margin-right: 8px;
  font-size: 12px;
  font-weight: bold;
}

.team-name {
  font-weight: 600;
  flex: 1;
}

.team-record {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #666;
  margin-top: 5px;
  margin-left: 32px;
}

.team-players-popup {
  position: absolute;
  top: 0;
  left: 100%;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  width: 200px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.team-players-popup h4 {
  margin-top: 0;
  margin-bottom: 8px;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
}

.team-players-popup ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.team-players-popup li {
  padding: 4px 0;
  font-size: 14px;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .tournament-bracket-container {
    overflow-x: auto;
  }
}

/* Connecting lines between rounds (optional enhancement) */
.round:not(:last-child) .matchup::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -10px;
  width: 10px;
  height: 2px;
  background-color: #ddd;
} 