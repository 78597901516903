.chat-sidebar {
  position: fixed;
  top: 0;
  right: -400px;
  width: 380px;
  height: 100vh;
  background-color: #1a1a1a;
  color: #f0f0f0;
  display: flex;
  flex-direction: column;
  transition: right 0.3s ease;
  z-index: 1000;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.3);
  border-left: 1px solid #333;
}

.chat-sidebar.open {
  right: 0;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #2a2a2a;
  border-bottom: 1px solid #333;
}

.chat-header h3 {
  margin: 0;
  font-size: 18px;
}

.close-button {
  background: none;
  border: none;
  color: #f0f0f0;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.message {
  max-width: 85%;
  padding: 10px 14px;
  border-radius: 12px;
  line-height: 1.4;
}

.message.user {
  align-self: flex-end;
  background-color: #0b93f6;
  color: white;
}

.message.assistant {
  align-self: flex-start;
  background-color: #3a3a3a;
  color: #f0f0f0;
}

.message-content {
  word-break: break-word;
}

.message-content p {
  margin: 0;
}

.chat-input-container {
  display: flex;
  padding: 15px;
  background-color: #2a2a2a;
  border-top: 1px solid #333;
}

.chat-input {
  flex: 1;
  padding: 10px 14px;
  border-radius: 20px;
  border: 1px solid #444;
  background-color: #333;
  color: #f0f0f0;
  font-size: 14px;
}

.chat-input:focus {
  outline: none;
  border-color: #0b93f6;
}

.send-button {
  margin-left: 10px;
  padding: 8px 16px;
  background-color: #0b93f6;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
}

.send-button:disabled {
  background-color: #555;
  cursor: not-allowed;
}

/* Scrollbar styling */
.chat-messages::-webkit-scrollbar {
  width: 6px;
}

.chat-messages::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: #444;
  border-radius: 3px;
}

/* Toggle button for the chat sidebar */
.chat-toggle-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #0b93f6;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 999;
  border: none;
  font-size: 24px;
} 